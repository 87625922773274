<script>
export default {
    data() {
        return {
            workdayBreakTypes: [
                {
                    value: null,
                    text: this.getI18n('WORKDAYS', 'TYPES.no_mandatory'),
                    invalid_message: this.getI18n('ERROR_CODES', 'invalid_business_unit'),
                },
                {
                    value: 'last_checkin',
                    text: this.getI18n('WORKDAYS', 'TYPES.last_checkin'),
                    invalid_message: this.getI18n('ERROR_CODES', 'invalid_pos'),
                },
                {
                    value: 'determined_time',
                    text: this.getI18n('WORKDAYS', 'TYPES.determined_time'),
                    invalid_message: this.getI18n('ERROR_CODES', 'invalid_chain'),
                },
            ],
        }
    },
}
</script>
