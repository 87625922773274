<template>
    <b-input-group class="col-sm-12 col-md-6 filter-bar">
        <b-form-input
            id="filterText"
            v-model="text"
            class="search-input"
            type="search"
            autocomplete="off"
            :placeholder="getI18n('PLACEHOLDERS', 'search')"
        />
        <b-input-group-append class="d-flex align-items-center">
            <i class="fe-search" />
        </b-input-group-append>
    </b-input-group>
</template>

<script>
export default {
    props: {
        filterText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {}
    },
    computed: {
        text: {
            get() {
                return this.filterText
            },

            set(newValue) {
                this.$emit('input', newValue)
            },
        },
    },
    methods: {
        resetFilter() {
            this.text = ''
        },
    },
}
</script>
