var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"col-12"},[_c('Breadcrumb',{attrs:{"items":_vm.items}}),_c('div',{staticClass:"row mt-3 mb-3"},[_c('div',{staticClass:"col-sm-6 form-inline"},[(!_vm.filtersVisible)?_c('filter-bar',{model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}}):_vm._e()],1),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"text-sm-right"},[(_vm.$can('CREATE', 'WORKDAY'))?_c('add-label-button',{attrs:{"title":_vm.i18nNewWorkday},on:{"onClick":_vm.openModalToCreate}}):_vm._e()],1)])]),_c('div',{staticClass:"card card-pdv"},[_c('PageHeader',{attrs:{"title":_vm.i18nWorkday(2)}}),_c('div',{staticClass:"card-body"},[_c('api-mode-vuetable',{ref:"vuetable",attrs:{"fetch-data":_vm.fetch,"fields":_vm.fields,"per-page":_vm.perPage,"additional-parameters":_vm.additionalParameters,"sort-order":_vm.sortOrder},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('count-table-button',{attrs:{"value":props.rowData.items.length},on:{"onClick":function($event){return _vm.openModalFrequencies(props.rowData)}}})]}},{key:"users",fn:function(props){return [_c('count-table-button',{attrs:{"value":props.rowData.users.length},on:{"onClick":function($event){return _vm.openModalUsers(props.rowData)}}})]}},{key:"actions",fn:function(props){return [_c('div',{staticClass:"table-button-container"},[_c('more-table-button',{on:{"onClick":function($event){return _vm.openModalToEdit(props.rowData)}}})],1)]}}])})],1)],1)],1),_c('workday-modal',{ref:"workdayModal",attrs:{"modal":_vm.modal,"modal-is-edit":_vm.modalIsEdit},on:{"refresh":_vm.reloadTable}}),_c('grid-form-modal',{ref:"gridFrequenciesModal",attrs:{"modal":_vm.modal,"items":_vm.modal.items,"fields":_vm.frequencyFields,"title":_vm.getI18nModified({
                prefix: 'WORKSCHEDULES',
                suffix: 'TITLES.frequency',
                modifier: 2,
            })}}),_c('grid-form-modal',{ref:"gridUsersModal",attrs:{"modal":_vm.modal,"items":_vm.modal.users,"fields":_vm.userChildrenFields,"title":_vm.getI18nModified({
                prefix: 'USERS',
                suffix: 'TITLES.user',
                modifier: 2,
            })}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }