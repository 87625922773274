<script>
export default {
    data() {
        return {}
    },
    methods: {
        validateRegisteredNumber(registeredNumber) {
            registeredNumber = registeredNumber.replace(/[^\d]+/g, '')

            const weightsFromFirstDigit = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
            const weightsFromSecondDigit = [
                6,
                5,
                4,
                3,
                2,
                9,
                8,
                7,
                6,
                5,
                4,
                3,
                2,
            ]

            return (
                this.excludeCommonFakes(registeredNumber) &&
                this.verifyDigit(registeredNumber, weightsFromFirstDigit) &&
                this.verifyDigit(registeredNumber, weightsFromSecondDigit)
            )
        },

        excludeCommonFakes(registeredNumber) {
            const excludeArray = [
                '00000000000000',
                '11111111111111',
                '22222222222222',
                '33333333333333',
                '44444444444444',
                '55555555555555',
                '66666666666666',
                '77777777777777',
                '88888888888888',
                '99999999999999',
            ]

            if (registeredNumber === '') return false
            if (registeredNumber.length !== 14) return false
            if (excludeArray.some((o) => registeredNumber === o)) return false

            return true
        },

        verifyDigit(registeredNumber, pesos) {
            const numbers = registeredNumber.split('').slice(0, pesos.length)

            const total = numbers.reduce((anterior, atual, index) => {
                return anterior + atual * pesos[index]
            }, 0)
            const remnant = total % 11
            const digit = remnant < 2 ? 0 : 11 - remnant
            return parseInt(registeredNumber[pesos.length]) === digit
        },
    },
}
</script>
