<template>
    <multiselect
        v-model="optionId"
        :class="selectClass"
        :options="options"
        :select-label="''"
        :selected-label="getI18n('MULTISELECT', 'selected_label')"
        :deselect-label="''"
        :placeholder="placeholder || getI18n('MULTISELECT', 'placeholder')"
        :track-by="trackBy"
        :label="label"
        :disabled="disabled"
        open-direction="bottom"
        :multiple="multiple"
        :searchable="searchable"
        :preselect-first="preselectFirst"
    >
        <span slot="noResult">
            {{ getI18n('MULTISELECT', 'no_result') }}
        </span>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: { Multiselect },
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {
            default: null,
        },
        options: {
            required: true,
            type: Array,
        },
        trackBy: {
            type: String,
            default: 'value',
        },
        label: {
            type: String,
            default: 'text',
        },
        selectClass: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        preselectFirst: {
            type: Boolean,
            required: false,
        }
    },

    computed: {
        optionId: {
            get() {
                return this.value
            },
            set(newValue) {
                const id = newValue || null
                this.$emit('input', id)
            },
        },
    },
}
</script>
