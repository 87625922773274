<script>
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import WorkdayService from '@src/services/WorkdayService'
import Layout from '@layouts/main.vue'
import workdayModal from '@views/workday/workdayModal.vue'
import GridFormModal from '@src/components/GridFormModal.vue'
import FilterBar from '@src/components/FilterBar'
import pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import workScheduleFrequencies from '@src/mixins/workScheduleFrequencies'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import CountTableButton from '@/src/components/CountTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import userChildrenFields from '@/src/fields/childrens/userChildrenFields.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import { debounce } from 'lodash'

const i18nKey = 'WORKDAYS'
const i18nCommon = 'COMMON'

export default {
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        workdayModal,
        GridFormModal,
        FilterBar,
        AddLabelButton,
        CountTableButton,
        MoreTableButton,
    },
    mixins: [
        pagination,
        swalFeedback,
        workScheduleFrequencies,
        userChildrenFields,
        vuetableFormatters
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            modalIsEdit: false,
            modal: {
                id: null,
                name: '',
                allow_break: true,
                block_out_of_period: false,
                tolerance: 0,
                workday_break: {
                    name: '',
                    break_time: 0,
                    mandatory: null,
                    start: '',
                    end: '',
                },
                items: [],
                users: [],
            },
            additionalParameters: {
                with: ['users', 'items', 'workdayBreak'],
            },
            data: [],
            perPage: 10,
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'allow_break',
                    title: this.getI18n(i18nKey, 'FIELDS.allow_break'),
                    sortField: 'allow_break',
                    formatter: (value) => {
                        return this.booleanIcons(value)
                    },
                },
                {
                    name: 'block_out_of_period',
                    title: this.getI18n(i18nKey, 'FIELDS.block_out_of_period'),
                    sortField: 'block_out_of_period',
                    formatter: (value) => {
                        return this.booleanIcons(value)
                    },
                },
                {
                    name: 'items',
                    title: this.getI18nModified({
                        prefix: 'WORKSCHEDULES',
                        suffix: 'TITLES.frequency',
                        modifier: 2,
                    }),
                },
                {
                    name: 'users',
                    title: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
                    }),
                },
                {
                    name: 'updated_at',
                    title: this.getI18n(i18nCommon, 'updated_at'),
                    sortField: 'updated_at',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            frequencyFields: [
                {
                    key: 'start',
                    label: this.getI18n(i18nCommon, 'start'),
                    sortable: true,
                },
                {
                    key: 'end',
                    label: this.getI18n(i18nCommon, 'end'),
                    sortable: true,
                },
                {
                    key: 'human_readable_frequencies',
                    label: this.getI18n(i18nCommon, 'frequency'),
                    sortable: true,
                    formatter: this.formatFrequency,
                },
            ],
            css: {},
            loadingEdit: [],
            filterText: '',
            filtersVisible: false,
            valueConsistsOf: 'BRANCH_PRIORITY',
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.collaborator',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.workday',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nNewWorkday() {
            return this.getI18n(i18nKey, 'BUTTONS.new_workday')
        },
    },

    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters = {
                search: '',
                with: ['users', 'items', 'workdayBreak'],
            }

            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        fetch(url, params) {
            return WorkdayService.fetchVuetable(url, params)
        },

        i18nWorkday(modifier) {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.workday',
                modifier: modifier,
            })
        },

        cleanModal() {
            this.modal = Object.assign(this.modal, {
                id: null,
                name: '',
                allow_break: true,
                block_out_of_period: false,
                tolerance: 0,
                workday_break: {
                    name: '',
                    break_time: 0,
                    mandatory: null,
                    start: '',
                    end: '',
                },
                items: [],
                users: [],
            })
        },

        showModal() {
            this.$nextTick(() => this.$refs.workdayModal.showModal())
        },

        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },

        openModalToEdit(workday) {
            this.modalIsEdit = false
            this.replaceModalData(workday)
            this.showModal()
        },

        openModalFrequencies(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridFrequenciesModal.showModal())
        },

        openModalUsers(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridUsersModal.showModal())
        },

        replaceModalData(data) {
            this.modal = JSON.parse(JSON.stringify(data));

            if (!this.modal.workday_break) {
                this.modal.workday_break = {
                    name: null,
                    break_time: 0,
                    mandatory: null,
                    start: null,
                    end: null,
                }
            }
        },

        reloadTable() {
            this.$refs.vuetable.reload()
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
    metaInfo() {
        return {
            title: this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.workday',
                modifier: 2,
            }),
        }
    },
}
</script>
<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar
                        v-if="!filtersVisible"
                        v-model="filterText"
                    ></filter-bar>
                </div>

                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'WORKDAY')"
                            :title="i18nNewWorkday"
                            @onClick="openModalToCreate"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nWorkday(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="items" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.items.length"
                                @onClick="openModalFrequencies(props.rowData)"
                            />
                        </template>
                        <template slot="users" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.users.length"
                                @onClick="openModalUsers(props.rowData)"
                            />
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <workday-modal
            ref="workdayModal"
            :modal="modal"
            :modal-is-edit="modalIsEdit"
            @refresh="reloadTable"
        />
        <grid-form-modal
            ref="gridFrequenciesModal"
            :modal="modal"
            :items="modal.items"
            :fields="frequencyFields"
            :title="
                getI18nModified({
                    prefix: 'WORKSCHEDULES',
                    suffix: 'TITLES.frequency',
                    modifier: 2,
                })
            "
        />
        <grid-form-modal
            ref="gridUsersModal"
            :modal="modal"
            :items="modal.users"
            :fields="userChildrenFields"
            :title="
                getI18nModified({
                    prefix: 'USERS',
                    suffix: 'TITLES.user',
                    modifier: 2,
                })
            "
        />
    </Layout>
</template>
