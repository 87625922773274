<script>
import SaveButton from '@/src/components/SaveButton'

const i18nCommon = 'COMMON'
const i18nRelations = 'RELATIONS'
const i18nResponses = 'RESPONSES'

export default {
    components: {
        SaveButton,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: false,
            default: '',
        },
        operation: {
            type: String,
            required: false,
            default: 'deleted',
        },
    },
    data() {
        return {
            i18nCommon,
            i18nRelations,
            statusCode: 0,
            items: [],
        }
    },
    computed: {
        i18nDescriptionModal() {
            if (this.operation === 'created') {
                return this.getI18n(i18nResponses, 'create_not_possible')
            } else if (this.operation === 'edited') {
                return this.getI18n(i18nResponses, 'update_not_possible')
            } else if (this.operation === 'deleted') {
                if (this.statusCode === '417') {
                    return `${this.getI18n(i18nRelations, 'MESSAGES.could_not_delete_record_from')} ${this.title.toLowerCase()} ${this.getI18n(i18nRelations, 'MESSAGES.because_it_has_a_link_with').toLowerCase()}:`
                }

                return this.getI18n(i18nResponses, 'delete_not_possible')
            } else if (this.operation === 'delete_all') {
                return `${this.getI18n(i18nCommon, 'delete_all')} ${this.title.toLowerCase()}`
            } else if (this.operation === 'delete_selected') {
                return `${this.getI18n(i18nCommon, 'delete')} ${this.title.toLowerCase()} ${this.getI18nModified({
                    prefix: i18nCommon,
                    suffix: 'selected',
                    modifier: 2,
                }).toLowerCase()}`
            } else {
                return ''
            }
        },
        i18nMessageModal() {
            if (this.items) {
                if (Array.isArray(this.items)) {
                    if (this.items.length > 0) {
                        let message = ''
                        for (var i = 0; i < this.items.length; i++) {
                            message += `${message ? `, ` : ''}${this.getI18n(i18nRelations, `TYPES.${this.items[i]}`) }`
                        }
                        return `${message}.`
                    }
                } else if (this.operation === 'delete_all' || this.operation === 'delete_selected') {
                    const messages = []
                    messages.push(`${this.i18nAmount} ${this.i18nRemoved}: ${this.items.data.deleted ?? 0}`)
                    messages.push(`${this.i18nAmount} ${this.getI18n(i18nCommon, 'no')} ${this.i18nRemoved}: ${this.items.data.not_deleted ?? 0}`)

                    if (this.items.message) {
                        messages.push(`${this.getI18n(i18nCommon, 'reason')}: ${this.items.message}`)
                    }
                    return messages
                }

                return this.items
            }

            return `${this.getI18n(i18nRelations, 'MESSAGES.no_bond')}.`
        },
        i18nAmount() {
            return this.getI18n('DASHBOARDS', 'TITLES.amount')
        },
        i18nRemoved() {
            return this.getI18nModified({
                    prefix: i18nCommon,
                    suffix: 'removed',
                    modifier: 0,
                })
        },
    },
    beforeMount() {},
    mounted() {},
    methods: {
        showModal(statusCode, items) {
            this.statusCode = statusCode
            this.items = items
            this.$refs.feedbackModal.show()
        },
        handleOk() {
            this.$refs.feedbackModal.hide()
        },
    },
}
</script>
<template>
    <div>
        <b-modal
            id="feedbackModal"
            ref="feedbackModal"
            centered
            :no-close-on-esc="true"
            :no-close-on-backdrop="true"
            :no-enforce-focus="true"
            size="md"
        >
            <b-row class="modal-body icon justify-content-center">
                <i
                    v-if="operation === 'created'"
                    class="fe-check-circle icon-green"
                />
                <i
                    v-else-if="operation === 'edited'"
                    class="fe-alert-triangle icon-orange"
                />
                <i
                    v-else-if="operation === 'deleted'"
                    class="fe-x-square icon-red"
                />
                <i
                    v-else-if="operation === 'delete_selected'"
                    class="fe-trash-2 icon-orange"
                />
                <i
                    v-else-if="operation === 'delete_all'"
                    class="fe-trash icon-red"
                />
            </b-row>
            <b-row class="modal-body description icon-gray">
                {{ i18nDescriptionModal }}
            </b-row>
            <b-row v-if="!Array.isArray(i18nMessageModal)" class="modal-body message">
                {{ i18nMessageModal }}
            </b-row>
            <b-row
                v-for="(value, index) in i18nMessageModal"
                v-else :key="index"
                class="modal-body message"
            >
                {{ value }}
            </b-row>
            <template v-slot:modal-header>
                <h4 class="modal-title">
                    {{ title }}
                </h4>
                <button class="close" @click="handleOk">
                    <i class="fe-x-circle icon-blue"/>
                </button>
            </template>
            <template v-slot:modal-footer>
                <div class="col text-center">
                    <save-button
                        :title="getI18n('COMMON', 'close')"
                        @onClick="handleOk"
                    />
                </div>
            </template>
        </b-modal>
    </div>
</template>
