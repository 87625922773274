<template>
    <button
        type="button"
        class="btn btn-pdv btn-pdv-green mr-1"
        href="javascript: void(0);"
        :disabled="disabled"
        @click="$emit('onClick')"
    >
        <i class="fe-plus-circle mr-1"></i>
        {{ title }}
    </button>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
}
</script>
