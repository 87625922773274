
<template>
    <form-modal
        ref="formModal"
        :handle-hide="cleanModal"
        :handle-open="resetValidation"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nWorkday"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nWorkday"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline"> </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'WORKDAY')"
                                :title="getI18n(i18nKey, 'TITLES.workday_resume')"
                                :busy="editLoading"
                                :disabled="deleteLoading"
                                @onClick="handleEdit"
                            />
                            <delete-label-button
                                v-if="$can('DELETE', 'WORKDAY')"
                                :title="getI18n(i18nKey, 'TITLES.workday_resume')"
                                :busy="deleteLoading"
                                :disabled="editLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="workday-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated">
                    <b-tab :title="i18nInformation">
                        <b-row>
                            <b-col class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nName"
                                    class="required label-pdv"
                                    label-for="modal-name"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_name')"
                                >
                                    <b-form-input
                                        id="modal-name"
                                        v-model.trim="$v.modalWorkday.name.$model"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        class="input-pdv-blue"
                                        :state="validateField('name', 'modalWorkday')"
                                        :disabled="!isEdit || submitLoading"
                                        @blur="$v.modalWorkday.name.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <b-form-group
                                    :label="i18nTolerance"
                                    label-for="modal-item-tolerance"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_tolerance')"
                                >
                                    <b-form-input
                                        id="modal-item-tolerance"
                                        v-model.trim="$v.modalWorkday.tolerance.$model"
                                        name="tolerance"
                                        aria-describedby="input-1-live-feedback"
                                        type="number"
                                        min="0"
                                        class="input-pdv-blue"
                                        :state="validateField('tolerance', 'modalWorkday')"
                                        :disabled="!isEdit || submitLoading || submitFrequencyLoading"
                                        @input="clearResponseError('tolerance', 'workdayItem')"
                                        @blur="$v.modalWorkday.tolerance.$touch;"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    class="label-pdv"
                                    :label="i18nBlockUseOfPeriod"
                                    label-for="modal-block-out-of-period"
                                >
                                    <b-form-checkbox
                                        id="modal-block-out-of-period"
                                        v-model="modalWorkday.block_out_of_period"
                                        size="lg"
                                        switch
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <b-form-group
                                    class="label-pdv"
                                    :label="i18nAllowBreak"
                                    label-for="modal-allow-break"
                                >
                                    <b-form-checkbox
                                        id="modal-allow-break"
                                        v-model="modalWorkday.allow_break"
                                        size="lg"
                                        switch
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nFrequency"
                                    class="required label-pdv"
                                    label-for="filter-frequency"
                                    :invalid-feedback="i18nFrequencyInvalid"
                                >
                                    <treeselect
                                        id="filter-frequency"
                                        v-model="workdayItem.human_readable_frequencies"
                                        :class="validationClass($v.workdayItem.human_readable_frequencies)"
                                        :multiple="true"
                                        :options="workScheduleFrequencies"
                                        :value-consists-of="valueConsistsOf"
                                        :placeholder="i18nFrequency"
                                        :disabled="!isEdit || submitLoading || submitFrequencyLoading"
                                        @input="$v.workdayItem.human_readable_frequencies.$touch;"
                                    />
                                </b-form-group>
                                <b-row>
                                    <b-col md="6">
                                        <b-form-group
                                            :label="i18nStart"
                                            class="required label-pdv"
                                            label-for="itemText"
                                            :invalid-feedback="i18nStartInvalid"
                                        >
                                            <date-picker
                                                v-model="$v.workdayItem.start.$model"
                                                :minute-step="15"
                                                :hour-options="hours"
                                                :class="validationClass($v.workdayItem.start)"
                                                format="HH:mm"
                                                value-type="format"
                                                type="time"
                                                :show-time-header="false"
                                                placeholder=""
                                                :disabled="!isEdit || submitLoading || submitFrequencyLoading"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6">
                                        <b-form-group
                                            :label="i18nEnd"
                                            class="required label-pdv"
                                            label-for="itemText"
                                            :invalid-feedback="i18nEndInvalid"
                                        >
                                            <date-picker
                                                v-model="$v.workdayItem.end.$model"
                                                :minute-step="15"
                                                :hour-options="hours"
                                                :class="validationClass($v.workdayItem.end)"
                                                format="HH:mm"
                                                value-type="format"
                                                type="time"
                                                :show-time-header="false"
                                                placeholder=""
                                                :disabled="!isEdit || submitLoading || submitFrequencyLoading"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <add-button
                                    ref="addFrequencyButton"
                                    :busy="submitFrequencyLoading"
                                    @onClick="handleAddFrequency"
                                />
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="modalWorkday.items"
                                    :fields="frequencyFields"
                                    :display-delete-button="isEdit"
                                    :busy="submitLoading || submitFrequencyLoading"
                                    @onClickDelete="handleRemoveFrequency"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nWorkdayBreak" :disabled="!modalWorkday.allow_break">
                        <b-row>
                            <b-col md="12">
                                <b-row>
                                    <b-col md="8">
                                        <b-form-group
                                            :label="i18nName"
                                            class="required label-pdv"
                                            label-for="modal-break-name"
                                            :invalid-feedback="getI18n('ERROR_CODES.invalid_name')"
                                        >
                                            <b-form-input
                                                id="modal-break-name"
                                                v-model.trim="$v.workdayBreak.name.$model"
                                                name="name"
                                                aria-describedby="input-1-live-feedback"
                                                type="text"
                                                class="input-pdv-blue"
                                                :state="validateField('name', 'workdayBreak')"
                                                :disabled="!isEdit || !modalWorkday.allow_break || submitLoading"
                                                @blur="$v.workdayBreak.name.$touch"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                        <b-form-group
                                            :label="i18nMandatory"
                                            label-for="modal-break-mandatory"
                                            class="required label-pdv"
                                        >
                                            <translated-multiselect
                                                id="modal-break-mandatory"
                                                v-model="mandatory"
                                                :options="workdayBreakTypes"
                                                :disabled="!isEdit || !modalWorkday.allow_break || submitLoading"
                                                @input="changeMandatory"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <!-- <b-form-group
                                    :label="i18nFrequency"
                                    class="required label-pdv"
                                    label-for="modal-break-frequency"
                                    :invalid-feedback="i18nFrequencyInvalid"
                                >
                                    <treeselect
                                        id="modal-break-frequency"
                                        v-model="$v.break.frequencies.$model"
                                        :class="validationClass($v.break.frequencies)"
                                        :multiple="true"
                                        :options="workScheduleFrequencies"
                                        :value-consists-of="valueConsistsOf"
                                        :placeholder="i18nFrequency"
                                        :disabled="!isEdit || !modalWorkday.allow_break || submitLoading"
                                    />
                                </b-form-group> -->
                                <b-row>
                                    <b-col md="2">
                                        <b-form-group
                                            :label="i18nDuration"
                                            label-for="modal-break-time"
                                            class="required label-pdv"
                                            :invalid-feedback="getI18n('ERROR_CODES.invalid_duration')"
                                        >
                                            <b-form-input
                                                id="modal-break-time"
                                                v-model.trim="$v.workdayBreak.break_time.$model"
                                                name="break-time"
                                                aria-describedby="input-1-live-feedback"
                                                type="number"
                                                min="1"
                                                class="input-pdv-blue"
                                                :state="validateField('break_time', 'workdayBreak')"
                                                :disabled="!isEdit || !modalWorkday.allow_break || submitLoading"
                                                @input="clearResponseError('break_time', 'workdayBreak')"
                                                @blur="$v.workdayBreak.break_time.$touch"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col v-if="workdayBreak.mandatory === 'determined_time'" md="3">
                                        <b-form-group
                                            :label="i18nStart"
                                            class="required label-pdv"
                                            label-for="itemText"
                                            :invalid-feedback="i18nStartInvalid"
                                        >
                                            <date-picker
                                                v-model="$v.workdayBreak.start.$model"
                                                :minute-step="15"
                                                :hour-options="hours"
                                                :class="validationClass($v.workdayBreak.start)"
                                                format="HH:mm"
                                                value-type="format"
                                                type="time"
                                                :show-time-header="false"
                                                placeholder=""
                                                :disabled="!isEdit || !modalWorkday.allow_break || submitLoading"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col v-if="workdayBreak.mandatory === 'determined_time'" md="3">
                                        <b-form-group
                                            :label="i18nEnd"
                                            class="required label-pdv"
                                            label-for="itemText"
                                            :invalid-feedback="i18nEndInvalid"
                                        >
                                            <date-picker
                                                v-model="$v.workdayBreak.end.$model"
                                                :minute-step="15"
                                                :hour-options="hours"
                                                :class="validationClass($v.workdayBreak.end)"
                                                format="HH:mm"
                                                value-type="format"
                                                type="time"
                                                :show-time-header="false"
                                                placeholder=""
                                                :disabled="!isEdit || !modalWorkday.allow_break || submitLoading"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nUser">
                        <b-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nUser"
                                    label-for="add-user-input"
                                    class="label-pdv" >
                                    <multiSelectWithService
                                        :id="'add-user-input'"
                                        ref="userMultiselect"
                                        v-model="userName"
                                        :service="'users'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                        :parameters="usersParameters"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="modalWorkday.users"
                                    :fields="userFields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemoveUser"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="tabIndex !== 2"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="submitLoading"
                    @onClick="tabIndex++"
                />
                <save-button
                    v-else-if="isWorkdayCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import workScheduleFrequencies from '@src/mixins/workScheduleFrequencies'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import workdayBreakTypes from '@src/mixins/workdayBreakTypes'
import WorkdayService from '@src/services/WorkdayService'
import WorkdayItemService from '@src/services/WorkdayItemService'
import pagination from '@src/mixins/pagination.vue'
import { required, integer, Boolean, minValue, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import multiSelectWithService from '@components/multiSelectWithService'
import Treeselect from '@riophae/vue-treeselect'
import translatedMultiselect from '@src/components/translatedMultiselect'
import DatePicker from 'vue2-datepicker'
import { intersection } from 'lodash'
import moment from 'moment'


const i18nKey = 'WORKDAYS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
        DataModeVuetable,
        multiSelectWithService,
        Treeselect,
        translatedMultiselect,
        DatePicker
    },
    mixins: [
        validationMixin,
        swalFeedback,
        fieldsValidation,
        vuetableFormatters,
        pagination,
        workScheduleFrequencies,
        workdayBreakTypes,
    ],
    props: {
        modal: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    name: '',
                    allow_break: true,
                    block_out_of_period: false,
                    tolerance: 0,
                    workday_break: {},
                    items: [],
                    users: [],
                    created_at: '',
                    updated_at: '',
                }
            },
        },
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
    },
    data() {
        return {
            i18nKey,
            i18nCommon,
            modalWorkday: {
                id: 0,
                name: '',
                allow_break: true,
                block_out_of_period: false,
                tolerance: 0,
                workday_break: {},
                items: [],
                users: [],
                created_at: '',
                updated_at: '',

            },
            workdayItem: {
                start: '',
                end: '',
                human_readable_frequencies: [],
            },
            workdayBreak: {
                name: null,
                break_time: 0,
                mandatory: null,
                start: null,
                end: null,
            },
            valueConsistsOf: 'LEAF_PRIORITY',
            hours: Array.from({ length: 10 }).map((_, i) => i + 8),
            isEdit: false,
            submitFrequencyLoading: false,
            submitLoading: false,
            editLoading: false,
            deleteLoading: false,
            userName: '',
            tabIndex: 0,
            perPage: 10,
            mandatory: null,
            additionalParameters: {
                search: `workday_id:${this.modal.id}`,
            },
            frequencyFields: [
                {
                    key: 'start',
                    label: this.getI18n(i18nCommon, 'start'),
                    sortable: true,
                },
                {
                    key: 'end',
                    label: this.getI18n(i18nCommon, 'end'),
                    sortable: true,
                },
                {
                    key: 'human_readable_frequencies',
                    label: this.getI18n(i18nCommon, 'frequency'),
                    sortable: true,
                    formatter: this.formatFrequency,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            userFields: [
                {
                    key: 'name',
                    label: this.getI18n('COMMON.user_name'),
                    sortable: true,
                },
                {
                    key: 'email',
                    label: this.getI18n('COMMON.email'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {
        modalWorkday: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
            tolerance: {
                required,
                integer,
                minValue: minValue(0),
                validateTolerance(value, model) {
                    let isValid = true
                    if (value > 0) {
                        model.items.forEach(item => {
                            if (isValid) {
                                const dateStart = new Date(2022, 1, 1, item.end.split(':')[0], item.end.split(':')[1])
                                const dateAfterTolerance = moment(dateStart).add(value, 'minutes').toDate()
                                isValid = moment(dateStart).isSame(dateAfterTolerance, 'day')
                            }
                        })
                    }
                    return isValid
                }
            },
            items: {
                required,
                conflictingPeriods(value, model) {
                    const items = model.items
                    let notConflicted = true
                    items.forEach((item, index) => {
                        items.forEach((otherItem, otherIndex) => {
                            if (index !== otherIndex) {
                                const conflictedFrequencies = intersection(
                                    otherItem.human_readable_frequencies,
                                    item.human_readable_frequencies
                                )
                                if (conflictedFrequencies.length > 0) {
                                    if (
                                        item.start < otherItem.end &&
                                        otherItem.start < item.end
                                    ) {
                                        notConflicted = false
                                    }
                                }
                            }
                        })
                    })
                    return notConflicted
                },
            },
        },
        workdayItem: {
            human_readable_frequencies: {
                required,
            },
            start: {
                required,
            },
            end: {
                required,
                afterStart(value, model) {
                    return value > model.start
                },
                validateTolerance(value, model) {
                    if (value && this.modalWorkday.tolerance > 0) {
                        const dateStart = new Date(2022, 1, 1, value.split(':')[0], value.split(':')[1])
                        const dateAfterTolerance = moment(dateStart).add(this.modalWorkday.tolerance, 'minutes').toDate()
                        return moment(dateStart).isSame(dateAfterTolerance, 'day')
                    }
                    return true
                }
            },
        },
        workdayBreak: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
            break_time: {
                required,
                integer,
                minValue(value) {
                    return value > 0
                },
            },
            start: {
                required: requiredIf(function (model) {
                    return model.mandatory === 'determined_time'
                }),
            },
            end: {
                required: requiredIf(function (model) {
                    return model.mandatory === 'determined_time'
                }),
                afterStart(value, model) {
                    return (value > model.start) || model.mandatory !== 'determined_time'
                },
            },
        }
    },
    computed: {
        isWorkdayCreated() {
            return this.modalWorkday.id > 0
        },
        i18nTitleModal() {
            if (this.isWorkdayCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${
                        this.i18nWorkday
                    }`
                }
                return this.modalWorkday.name
            } else {
                return this.getI18n(i18nKey, 'BUTTONS.new_workday')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nRequiredCheckin() {
            return this.getI18n(i18nKey, 'TITLES.required_checkin')
        },
        i18nWorkday() {
            return this.getI18n(i18nKey, 'TITLES.workday')
        },
        i18nFrequency() {
            return this.getI18n('COMMON', 'frequency')
        },
        i18nFrequencyInvalid() {
            return this.getI18n('ERROR_CODES', 'invalid_frequency')
        },
        i18nStart() {
            return this.getI18n('COMMON', 'start')
        },
        i18nStartInvalid() {
            return this.getI18n('ERROR_CODES', 'invalid_start')
        },
        i18nEnd() {
            return this.getI18n('COMMON', 'end')
        },
        i18nEndInvalid() {
            return this.getI18n('ERROR_CODES', 'invalid_end')
        },
        i18nTolerance() {
            return this.getI18n(i18nKey, 'FIELDS.tolerance')
        },
        i18nAllowBreak() {
            return this.getI18n(i18nKey, 'FIELDS.allow_break')
        },
        i18nBlockUseOfPeriod() {
            return this.getI18n(i18nKey, 'FIELDS.block_out_of_period_journey')
        },
        i18nDuration() {
            return this.getI18n(i18nKey, 'FIELDS.duration')
        },
        i18nMandatory() {
            return this.getI18n(i18nKey, 'FIELDS.mandatory')
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: 'COMMON.info',
                modifier: 2,
            })
        },
        i18nWorkdayBreak(){
            return this.getI18nModified({
                prefix: 'WORKDAYS_BREAKS',
                suffix: 'TITLES.workday_break',
                modifier: 2,
            })
        },
        i18nUser(){
            return this.getI18nModified({
                prefix: 'USERS',
                suffix: 'TITLES.user',
                modifier: 2,
            })
        },
        modalOperation() {
            return this.isWorkdayCreated ? 'edited' : 'created'
        },
    },
    watch: {
        userName(value) {
            if (value) {
                const index = this.modalWorkday.users.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.modalWorkday.users.push(value)
                }
                this.userName = ''
                this.$refs.userMultiselect.$el.focus()
            }
        },
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex, event) {
            if (this.isEdit) {
                if (oldTabIndex === 0) {
                    this.$v.modalWorkday.$touch()
                    if (this.modalWorkday.items.length <= 0) {
                        this.$v.workdayItem.$touch()
                    }
                    if (this.$v.modalWorkday.$invalid || (this.$v.workdayItem.$invalid && this.modalWorkday.items.length <= 0)) {
                        event.preventDefault();
                    }
                }
                else if (oldTabIndex === 1 && newTabIndex > oldTabIndex) {
                    this.$v.workdayBreak.$touch()
                    if (this.$v.workdayBreak.$invalid) {
                        event.preventDefault();
                    }
                }

                if (this.modalWorkday.allow_break && (newTabIndex > oldTabIndex) && (newTabIndex - oldTabIndex > 1)) {
                    event.preventDefault();
                    this.tabIndex = oldTabIndex + 1
                }
            }
        },
        changeMandatory(option) {
            this.workdayBreak.mandatory = option.value
            if (this.workdayBreak.mandatory !== 'determined_time') {
                this.workdayBreak.start = null
                this.workdayBreak.end = null
            }
        },
        fetch(url, params) {
            return WorkdayItemService.fetchVuetable(url, params)
        },
        showModal() {
            this.isEdit = this.modalIsEdit
            this.submitFrequencyLoading = false
            this.submitLoading = false
            this.editLoading = false
            this.deleteLoading = false
            this.tabIndex = 0
            this.additionalParameters = {
                search: `workday_id:${this.modal.id}`,
            }
            this.modalWorkday = this.modal
            this.workdayItem = Object.assign(this.workdayItem, {
                start: '',
                end: '',
                human_readable_frequencies: [],
            })
            this.workdayBreak = this.modalWorkday.workday_break
            this.mandatory = this.workdayBreakTypes.find(find => find.value === this.workdayBreak.mandatory)

            this.$refs.formModal.show()
            this.resetValidation()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        cleanModal() {
            this.mandatory = null

            this.modalWorkday = Object.assign(this.modalWorkday, {
                id: null,
                name: '',
                required_checkin: true,
                allow_break: true,
                block_out_of_period: false,
                tolerance: 0,
                workday_break: {},
                items: [],
                users: [],
                created_at: '',
                updated_at: '',
            })

            this.workdayItem = Object.assign(this.workdayItem, {
                start: '',
                end: '',
                human_readable_frequencies: [],
            })

            this.workdayBreak = Object.assign(this.workdayBreak, {
                name: null,
                break_time: 0,
                mandatory: null,
                start: null,
                end: null,
            })

            this.$v.modalWorkday.$reset()
            this.$v.workdayItem.$reset()
            this.$v.workdayBreak.$reset()
        },
        handleEdit() {
            this.editLoading = true

            this.isEdit = true
            this.tabIndex = 0
            this.editLoading = false
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        handleOk() {
            this.$v.modalWorkday.$touch()
            this.$v.workdayBreak.$touch()
            if (!this.$v.modalWorkday.$invalid && (!this.$v.workdayBreak.$invalid || !this.modalWorkday.allow_break)  && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const workdayInfo = {
                name: this.modalWorkday.name,
                allow_break: this.modalWorkday.allow_break,
                block_out_of_period: this.modalWorkday.block_out_of_period,
                tolerance: this.modalWorkday.tolerance,
                workday_break: this.modalWorkday.allow_break ? {
                    id: this.workdayBreak.id,
                    break_time: this.workdayBreak.break_time,
                    mandatory: this.workdayBreak.mandatory,
                    start: this.workdayBreak.start,
                    end: this.workdayBreak.end,
                    name: this.workdayBreak.name,
                } : null,
                items: this.modalWorkday.items,
                users: this.modalWorkday.users,
            }

            const operation = this.isWorkdayCreated
                ? WorkdayService.update(this.modalWorkday.id, workdayInfo)
                : WorkdayService.create(workdayInfo)

            const response = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nWorkday, this.modalOperation, '3')
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
                this.warningFeedbackApi(this.i18nWorkday, response.data.errors)
            }

            this.submitLoading = false
        },

        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteWorkday)
        },
        async deleteWorkday(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.modalWorkday.$touch()
            this.deleteLoading = true
            const response = await WorkdayService.delete(this.modalWorkday.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nWorkday, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },

        handleAddFrequency() {
            this.$v.workdayItem.$touch()
            if (!this.$v.workdayItem.$invalid && !this.submitFrequencyLoading) {
                this.submitFrequencyLoading = true

                this.modalWorkday.items.push({
                    id: 0,
                    start: this.workdayItem.start,
                    end: this.workdayItem.end,
                    human_readable_frequencies: this.workdayItem.human_readable_frequencies,
                })

                this.workdayItem = Object.assign(this.workdayItem, {
                    start: '',
                    end: '',
                    human_readable_frequencies: [],
                })

                this.$v.workdayItem.$reset()

                this.submitFrequencyLoading = false
            }
        },

        handleRemoveFrequency(id) {
            const index = this.modalWorkday.items.findIndex(find => find.id === id);

            if (index > -1) {
                this.modalWorkday.items.splice(index, 1);
            }
        },

        handleRemoveUser(id) {
            const index = this.modalWorkday.users.findIndex(find => find.id === id);

            if (index > -1) {
                this.modalWorkday.users.splice(index, 1);
            }
        },
    },
}
</script>
