<template>
    <div>
        <div class="table-responsive table-pdv" :class="small ? 'table-pdv-sm' : ''">
            <b-table
                hover
                :fields="fields"
                :items="value"
                :busy="busy"
                show-empty
                sticky-header
            >
                <template v-slot:table-busy>
                    <b-skeleton-table
                    :rows="4"
                    :columns="fields.length"
                    :table-props="{
                        hover: true,
                        noBorderCollapse: true,
                    }"
                    />
                </template>
                <template v-slot:empty>
                    <div align="center">
                    <p>{{ getI18n('PAGINATION', 'no_records') }}</p>
                    </div>
                </template>
                <template
                    v-for="(_, slotName) of $scopedSlots"
                    v-slot:[slotName]="scope"
                >
                    <slot :id="slotName" :name="slotName" v-bind="scope" />
                </template>
                <template v-slot:cell(actions)="row">
                    <div class="text-right">
                        <delete-table-button
                            v-if="displayDeleteButton"
                            :disabled="busy || disabled"
                            :busy="busyDelete"
                            @onClick="$emit('onClickDelete', row.item.id)"
                        />
                    </div>
                </template>
            </b-table>
        </div>
        <div class="text-right mr-1">
            <label class="label-pdv">
                {{ footerTotalizer }}
            </label>
        </div>
    </div>
</template>

<script>
import DeleteTableButton from '@/src/components/DeleteTableButton.vue'
import vuetableFormatters from '@/src/mixins/vuetableFormatters'
import { sumBy } from 'lodash'

export default {
    components: {
        DeleteTableButton,
    },
    mixins: [vuetableFormatters],
    props: {
        value: {
            type: Array,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        busyDelete: {
            type: Boolean,
            required: false,
        },
        displayDeleteButton: {
            type: Boolean,
            required: false,
        },
        totalizingField: {
            type: String,
            required: false,
            default: '',
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {}
    },
    computed: {
        i18nNumberOfRecords() {
            return (
                this.getI18n('PAGINATION.total') +
                ' ' +
                this.getI18n('PAGINATION.of') +
                ' ' +
                this.getI18nModified({
                    prefix: 'PAGINATION.records',
                    modifier: 2,
                }) +
                ':'
            )
        },
        footerTotalizer() {
            if (this.totalizingField) {
                const total = sumBy(this.value, this.totalizingField)
                return `${this.getI18n('PAGINATION.total')}: ${this.getI18nCurrency(total)}`
            }

            return `${this.i18nNumberOfRecords} ${this.padLeft(this.value.length)}`
        }
    },
}
</script>

<style>
.b-table-busy-slot > td > table > thead {
    display:none !important;
}
</style>
