<template>
    <form-modal ref="formModal" :title="titleModal" size="xl">
        <template v-slot:form>
            <form>
                <div class="row">
                    <div class="col-sm-6 form-inline">
                        <filter-bar v-model="filterText"></filter-bar>
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <slot></slot>
                        </div>
                    </div>
                </div>
                <b-table
                    hover
                    :fields="fields"
                    :items="itemsComp"
                    :filter="filterText"
                    :current-page="currentPage"
                    :per-page="perPage"
                >
                    <template
                        v-for="(_, slotName) of $scopedSlots"
                        v-slot:[slotName]="scope"
                    >
                        <slot :id="slotName" :name="slotName" v-bind="scope" />
                    </template>
                </b-table>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="itemsComp.length"
                    :per-page="perPage"
                    align="right"
                    variant="danger"
                />
            </form>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@/src/components/FormModal.vue'
import FilterBar from '@src/components/FilterBar'

export default {
    components: {
        FormModal,
        FilterBar,
    },
    props: {
        modal: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    name: '',
                    items: [],
                }
            },
        },
        title: {
            type: String,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        perPage: {
            type: Number,
            required: false,
            default: 10,
        },
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            gridModal: this.modal,
            itemsModal: this.items,
            titleModal: this.title,
            filterText: null,
            currentPage: 1
        }
    },
    computed: {
        itemsComp: {
            get() {
                return this.itemsModal
            },
            set(value) {
                this.$emit('itemsModal', value)
            },
        },
    },
    methods: {
        hideModal() {
            this.$refs.formModal.hide()
        },
        showModal() {
            this.gridModal = this.modal
            this.itemsModal = this.items
            if (this.gridModal.name) {
                this.titleModal = `${this.title} (${this.gridModal.name})`
            } else {
                this.titleModal = this.title
            }

            this.$refs.formModal.show()
        },
    },
}
</script>
