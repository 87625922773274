<template>
    <a
        href="javascript: void(0);"
        class="action-icon"
        @click="disabled ? 'return false;'  : $emit('onClick')">
        <template v-if="busy">
            <b-spinner small></b-spinner>
        </template>
        <template v-else><i class="fe-x-circle icon-blue"/></template>
    </a>
</template>

<script>
export default {
    props: {
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
}
</script>
