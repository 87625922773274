<template>
    <a
        v-if="value > 0"
        href="javascript: void(0);"
        @click="$emit('onClick')">
        {{ padLeft(value) }}
        <i class="fe-eye icon-blue ml-1"/>
    </a>
    <div v-else>
        {{ padLeft(value) }}
        <i class="fe-eye-off ml-1"/>
    </div>
</template>

<script>
import vuetableFormatters from '@src/mixins/vuetableFormatters'

export default {
    mixins: [
        vuetableFormatters,
    ],
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
}
</script>

<style scoped>
a {
  color: #66686D !important;
}
</style>
