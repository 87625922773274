import BaseService from '@src/services/BaseService'

class WorkdayService extends BaseService {
    constructor() {
        super('workdays')
    }

    assignUsers(workdayId, usersId) {
        return this.axios.post(`${this.getUrl()}/${workdayId}/assign-users`, {
            users: usersId,
        })
    }

    removeUsers(workdayId, usersId) {
        return this.axios.post(`${this.getUrl()}/${workdayId}/remove-users`, {
            users: usersId,
        })
    }
}
export default new WorkdayService()
